function currentState({ basic_data }) {
    return { ...basic_data };
}

export function compareTo(a, b) {
    return a.name.localeCompare(b.name);
}

export const mapState = (states) => {
    const defaultStates = [
        {
            name: 'ALL',
            id: 0,
            code: undefined,
        },
    ];

    defaultStates.push(...states.map(currentState).sort(compareTo));

    return defaultStates;
};

export const mapBoard = (boards) => {
    const defaultStates = [
        {
            name: 'ALL',
            code: undefined,
            in_status: 1,
        },
    ];

    defaultStates.push(...boards.map(currentState).sort(compareTo));

    return defaultStates;
};

export const mapProfession = (professions) => {
    const defaultProfessions = [
        {
            name: 'ALL',
            code: undefined,
        },
    ];

    defaultProfessions.push(
        ...professions
            .map(currentState)
            .filter(({ in_active }) => Boolean(in_active))
            .sort(compareTo)
    );

    return defaultProfessions;
};

export const setCookies = (key, value) => {
    document.cookie = `${key}=${value}`;
};

export const cookies = () => {
    const cookie = document.cookie;
    const params = cookie.split(';').reduce((prev, row) => {
        const [key, value] = row.split('=');
        return { ...prev, [key.trim()]: value };
    }, {});

    return params;
};

export const getAuth = () => {
    const userId = localStorage.getItem('userId');
    try {
        const user = JSON.parse(localStorage.getItem(`${userId}`));
        return user;
    } catch (error) {
        return null;
    }
};

export const licenseStorage = {
    key: 'currentLicense',
    setLicense: function (license) {
        const licenseString = JSON.stringify(license);
        sessionStorage.setItem(this.key, licenseString);
    },
    getLicense: function () {
        const licenseString = sessionStorage.getItem(this.key);
        try {
            const license = JSON.parse(licenseString);
            return license;
        } catch (error) {
            sessionStorage.removeItem(this.key);
            return null;
        }
    },
    removeLicense: function () {
        sessionStorage.removeItem(this.key);
    },
};

export const getQueryParams = (search = '') => {
    const searchArray = search.replace('?', '').split('&');

    const query = {};
    searchArray.forEach((stringQuery) => {
        const [key, value] = stringQuery.split('=');
        const numberValue = Number(value);
        query[key] = isNaN(numberValue) ? value : numberValue;
    });

    return query;
};

export const orderQueryResult = (a, b) => {
    var nameA = a.basic_data.name.toUpperCase();
    var nameB = b.basic_data.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
};

export const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getUserName = () => {
    const username = localStorage.getItem('username');

    if (username) {
        return `${username.toLowerCase()}`;
    }

    return 'user';
};

export const destructuringErrors = (errorsRaw = []) => {
    const errors = [];

    if (errorsRaw && errorsRaw.length) {
        errorsRaw.forEach((error) => errors.push(error));
    }

    const errorsFlatted = [];

    errors
        .filter((error) => Boolean(error))
        .forEach((error) => {
            const { response } = error;
            const {
                data: { details },
            } = response;

            if (details && details.length) {
                details.forEach((detail) => {
                    const { path, message } = detail;
                    errorsFlatted.push({
                        response: {
                            data: {
                                error: path,
                                comment: message,
                            },
                        },
                    });
                });
            }

            if (!details) {
                errorsFlatted.push({ response });
            }
        });

    const errorsFormatted = errorsFlatted.map((singleError) => {
        const {
            response: { data },
        } = singleError;
        const { error, comment: dataComment, stage: dataStage } = data;
        const stage = dataStage ? `stage ${dataStage}` : '';
        const comment = [dataComment, stage].filter((comment) => Boolean(comment)).join(' ');
        return {
            response: {
                data: {
                    error: String(error).toUpperCase(),
                    comment: String(comment).replace(/_/gm, ' ').toLowerCase(),
                },
            },
        };
    });

    return errorsFormatted;
};

export const compareArrays = function (first = [], last = []) {
    if (!last) {
        return false;
    }

    // eslint-disable-next-line eqeqeq
    if (first.length != last.length) {
        return false;
    }

    for (var i = 0, l = first.length; i < l; i++) {
        if (first[i] instanceof Array && last[i] instanceof Array) {
            if (!first[i].equals(last[i])) {
                return false;
            }
        // eslint-disable-next-line eqeqeq
        } else if (first[i] != last[i]) {
            return false;
        }
    }

    return true;
};
