import React, { lazy } from "react";

const Summary = lazy(() => import("./modules/SumaryPage"));
const Dashboard = lazy(() => import("./modules/Dashboard"));
const ManageLicenses = lazy(() => import("./modules/ManageLicenses"));
const BoardGadget = lazy(() => import("./modules/BoardGadget"));
const Login = lazy(() => import("./modules/Login"));
const DeniedAccess = lazy(() => import("./modules/Login/containers/DeniedAccess"));

export const PATH_ROOT = "/";
export const PATH_SUMMARY = "/summary";
export const PATH_MANAGE_LICENSES = "/manage_license_helper";
export const PATH_BOARD_GADGET = "/board_gadget";
export const PATH_LOGIN = "/login";
export const PATH_DENIED_ACCESS = "/denied_access";

export const ROUTES = [
  {
    path: PATH_SUMMARY,
    render: () => <Summary />,
    exact: true,
  },
  {
    path: PATH_ROOT,
    render: (props) => <Dashboard {...props} />,
    exact: true,
  },
  {
    path: PATH_MANAGE_LICENSES,
    children: (props) => <ManageLicenses {...props} />,
    exact: true,
  },
  {
    path: PATH_BOARD_GADGET,
    children: (props) => <BoardGadget {...props} />,
    exact: true,
  },
  {
    path: PATH_LOGIN,
    render: () => <Login />,
    exact: true,
  },
  {
    path: PATH_DENIED_ACCESS,
    render: () => <DeniedAccess />,
    exact: true,
  },
];

export default ROUTES;
