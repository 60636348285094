import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Loader from "./components/Loader";
import { cookies, getQueryParams } from "./util";

import ROUTES from "./Routes";

import "./App.css";

const { REACT_APP_LAUNCHPAD_URL, REACT_APP_CEB_TOKEN } = process.env;

const REDIRECT = false;

export const Wrapper = () => {
  const [loadPage, setLoadPage] = useState(false);
  const {
    location: { hostname, search },
  } = window;

  useEffect(() => {
    (async () => {
      const cookie = cookies();
      const token = cookie[REACT_APP_CEB_TOKEN];

      if (token) {
        setLoadPage((prevstate) => !prevstate);
      } else {
        const cookie_pass = getQueryParams(search);
        const token_pass = cookie_pass[REACT_APP_CEB_TOKEN];

        if (token_pass) {
          setLoadPage((prevstate) => !prevstate);
          return null;
        }

        if (hostname !== "localhost") {
          window.location.assign(REACT_APP_LAUNCHPAD_URL);
        }
      }
    })();
  }, [hostname, search]);

  if (!loadPage) return null;

  return (
    <React.Suspense fallback={<Loader full />}>
      <Router>
        {ROUTES.map((route, i) => (
          <Route key={`${route.path}-${i}`} {...route} />
        ))}
        {REDIRECT && <Redirect to="/" />}
      </Router>
    </React.Suspense>
  );
};

export default Wrapper;
