import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import "./styles.css";

export const Loader = ({ full = false, loading = true, size = 100, color = "#061630" }) => {
    const loader = (
        <div className="loader-wrapper">
            <ClipLoader size={size} color={color} loading={loading} />
        </div>
    );

    if (full) {
        return (
            <div className="full-loader">
                {loader}
            </div>
        );
    }

    return loader;
};

export default Loader;
